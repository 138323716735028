// material-ui

// project imports
import NavGroup from './navGroup';
import menuItem from 'menu-items';
import NavItem from './navItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const menu = menuItem();
    console.log(menu);
    const navItems = menu?.items
        ? menu?.items?.map((item) => {
              switch (item?.type) {
                  case 'group':
                      return <NavGroup key={item?.id} item={item} />;
                  case 'single':
                      return <NavItem key={item.id} item={item} />;
              }
          })
        : null;

    return <>{navItems}</>;
};

export default MenuList;
