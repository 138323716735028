import { useSelector } from 'react-redux';
import MainMenu from './mainMenu';
import { useEffect } from 'react';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
    const role = useSelector((state) => state.authReducer.user_info.roles);
    const role2 = JSON.parse(localStorage.getItem('user_info'))?.roles;

    useEffect(() => {
        console.log(role, role2);
    }, [role, role2]);

    return {
        items: [MainMenu(role2 === undefined ? role : role2)]
    };
};

export default MenuItems;
